import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import Insta from './insta';
import ExploreCollection from './ExploreCollection';
import Customer from './Customer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import { client_name } from "../../Helpers/request";
import LooseCreateDiamond from "./LooseCreateDiamond";
import EarNeck from "./EarNeck";
import JewelryRepair from "./JewelryRepair";
import BrandLogos from "./BrandLogos";
import Testimonial from "./Testimonial";
import popupimage from "../../Assets/images/popup.jpeg";
import { Modal } from "react-bootstrap";
import Snowfall from "react-snowfall";
import ShyCreation from "./ShyCreation";

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);


  // const [show, setShow] = useState(false);
  // let timeoutId;

  // const handleClose = () => {
  //   setShow(false);
  //   clearTimeout(timeoutId);
  // };

  // useEffect(() => {
  //   timeoutId = setTimeout(() => {
  //     setShow(true);
  //   }, 1000);
  //   setInterval(() => {
  //     setShow(false)
  //   }, 5000)

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []); 



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content={client_name.c_name} />
      </Helmet>
      {/* <OnLoadModal popupimage={popupimage} showOnLoad={showOnLoad} handleCloseOnLoad={handleCloseOnLoad} /> */}
      <div className="al_snowfall" style={{ height: '100%', width: '100%', background: '#282c34', overflow: 'hidden' }}>
        <Snowfall  color="#fff"   snowflakeCount={300}  className="al_snowfall_canvas" />
      </div>
      <BannerSlider />
      <ExploreCollection />
      <LooseCreateDiamond />
      <ShyCreation />
      <BrandLogos />
      {/* <EarNeck/> */}
      <JewelryRepair />
      {/* <Customer /> */}
      <Testimonial />
      <Insta />

      {/* <Modal
        show={show}
        onHide={handleClose}
        size='xl'
        className='onloadmodal'
      >
        <Modal.Header className='p-0'>
          <button
            type="button"
            onClick={handleClose}
            className="close"
            aria-label="Close"
            style={{ position: 'absolute', right: '10px', top: '10px', zIndex: '1' }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <img
            className="img-fluid"
            alt="Popup"
            src={popupimage}
          />
        </Modal.Body>
      </Modal> */}

    </>
  )
}

export default Home;